<template>
  <div class="for-students">
    <div class="intro-full-w bg-img">
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
        <div >
    <div class="two-column-layout section" v-if="customText && customText.course">
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-6 order-lg-1 order-md-2 text">
            <h6>THE COURSE</h6>
            <h3 class="light">{{customText.course.students_course_titolo}}</h3>
            <div v-html="customText.course.students_course_testo">
            </div>
          </div>
          <div class="col-lg-6 order-lg-2 order-md-1 image"></div>
        </div>
      </div>
    </div>

    <div class="section offset-tiles" v-if="customText && customText.facilities">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-lg-1 image ">
            <img src="../assets/home-careers.jpg" alt="">
          </div>
          <div class="col-lg-6 order-lg-2 d-flex flex-column justify-content-center text">
            <div>
              <h6>Facilities</h6>
              <h3 class=light>{{customText.facilities.students_facilities_titolo}}</h3>
              <p v-html="customText.facilities.students_facilities_testo"></p>
              <router-link class="read-more white" to="/experimental-facilities">read more</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="careers-wrapper" v-if="customText && customText.careers">
      <two-sides-list 
        label="Job perspectives"
        title="Careers"
        :description="customText.careers.students_careers_intro"
        :items="careersItems"
      />
    </div>
    <div class="section my-2 my-lg-5" v-if="customText && customText.join">
      <div class="container">
        <h3 class="green">{{customText.join.students_join_titolo}}</h3>
        <div class="text-block" v-html="customText.join.students_join_testo"></div>
        <div class="buttons-container">
          <router-link to="/how-apply">
            <button class="cta-big icon-book m-lg-3">
              <label class="top">MHPC FOR STUDENTS</label>
              <label class="bottom">How to Apply</label>
            </button>
          </router-link>
        </div>
      </div>
    </div>
        </div>
</div>
</template>
<script>
import IntroPage from "../components/IntroPage.vue";
import TwoSidesList from "../components/TwoSidesList.vue";

export default {
  name: "students",
  components: {
    IntroPage,
    TwoSidesList
  },
  data() {
    return {
      currentPageId: "1bb691c6-13f6-46d9-b389-9ea637d8d439",
      careersDescription: "After completing the specialization program, many students are included in the labor market as a continuation of the stage for the thesis. 100% of our former students work in their field of expertise in academia or industry (30% with permanent contracts).",
      careersItems: [],
      customText: undefined
    };
  },
  methods: {
    fetchPageData(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/students`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.customText = json.data;
          //console.log('this.customText', this.customText.facilities.students_facilities_titolo);

          if(this.customText.careers){
            Object.keys(this.customText.careers).forEach((key)=>{
              if(key!=='students_careers_intro'){
                this.careersItems.push({text:this.customText.careers[key]})
              }
            })
          }
          (this.customText)
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      },
  },
  mounted() {
    this.fetchPageData()
  },
};
</script>
<style lang="scss" scoped>

.for-students .offset-tiles{
    &:after{
        background: #293446;
        width: 100%;
        height: 100px;
        content: "";
        bottom: 0;
        left: 0;
        position: absolute;
        display: block;
        z-index: 0;
    }
    .container {
      position: relative;
      z-index: 1;
    }
}


.for-students .intro-full-w {
  background: url(../assets/intro-for-students.png) no-repeat;
  background-position: center right;
  background-size: contain;
}
.two-column-layout {
  .image {
    background-image: url("../assets/home-about-1.jpg");
  }
}
.offset-tiles{
  position: relative;
  background:#eeeeee;
  .row div.image{
    max-height: none;
  }



  @media (min-width:992px) {
    &::before{
      height: 100px;
      background-color: #293446;
    }
    padding-bottom: 100px;
    .row {
      div.text{
        top: 100px
      }
    } 
    .image {
      top: 0;
    }
  }
}

.cta-big.icon-book:before {
  background: url("../assets/icon-book.svg");
}

.text-block{
  color:#ACACAC;
  @media (min-width:992px) {
    margin-right: 20%;
  }
}

.buttons-container{
  margin-top: 40px;
}
</style>