<template>
  <div class="wrapper">
    <div class="two-side-list container">
      <div>
        <h6>{{ label }}</h6>
        <h3 class="light">{{ title }}</h3>
        <div v-html="description"></div>
        <div class="list">
          <div v-for="(item, index) of items" :key="index" class="list-item">
            <div>
              <div class="list-item--title" v-if="item.title">
                {{ item.title }}
              </div>
              <div class="list-item--text" v-if="item.text" v-html="item.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    title: String,
    description: String,
    items: Array,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding-top: 60px;
  padding-bottom: 100px;
  background: #293446;
  overflow: hidden;
  h3 {
    color: #fff;
  }
  .list-item--text {
    color: #fff; 
  }
  &::v-deep p{
    color: #acacac; 
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
}
.list-item {
  font-size: 16px;
  position: relative;
  padding: 20px 40px 20px 0;
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: #fff;
  vertical-align: top;
  width: 100%;
  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: -200%;
    width: 300%;
    height: 100%;
    z-index: 9;
    content: "";
    border-top: 1px solid #1dc07e;
    border-bottom: 1px solid #1dc07e;
    border-right: 1px solid #1dc07e;
    padding: 30px 90px;
    border-radius: 0px 182px 182px 0px;
  }
  .list-item--title{
    font-size: 32px;
    font-weight: 200;
    & + .list-item--text{
      margin-top: 15px;
      color: #ACACAC;
      font-size: 16px;
    }
  }

  @media (min-width: 992px) {
    padding: 60px 90px;
    padding-left: 0;
    width: calc(50% - 40px);
    margin-right: 20px;
    margin-left: 0;
    &:nth-child(2n) {
      position: relative;
      padding-left: 90px;
      padding-right: 0;
      top: 40px;
      &::before {
        border-left: 1px solid #1dc07e;
        border-right: 0;
        border-radius: 182px 0px 0px 182px;
        right: -200%;
        left: auto;
      }
    }
  }
}
@media (min-width:992px) {
  .wrapper{
    padding-top: 100px;
    padding-bottom: 140px;
  }
}
</style>